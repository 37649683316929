



























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Model } from 'vue-property-decorator';
import * as _ from 'lodash';

import BaseFormFileInput from '../components/BaseFormFileInput.vue';
import {
  FormElement,
  FormElementType,
} from '../shared/types/form-element.class';

// The @Component decorator indicates the class is a Vue component
@Component({
  name: 'BaseFormElement',
  components: {
    BaseFormFileInput,
  },
})
export default class BaseFormElement extends Vue {
  @Model('change')
  data!: object;

  @Prop()
  element!: FormElement | FormElement[];

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }

  onChange(element: FormElement, value: any) {
    if (this.isDate(element) && value instanceof Array) {
      value = value[0];
    }
    if (_.isEmpty(element.field)) {
      this.data = value;
      return;
    }
    _.set(this.data, element.field as string, value);
  }

  isDisabled(element: FormElement) {
    if (element.options.disabled instanceof Function) {
      return element.options.disabled(element);
    }
    return element.options.disabled;
  }

  isText(element: FormElement) {
    if (
      element.type === FormElementType.TEXT ||
      element.type === FormElementType.PASSWORD
    ) {
      return true;
    }
    return false;
  }

  isNumber(element: FormElement) {
    if (element.type === FormElementType.NUMBER) {
      return true;
    }
    return false;
  }

  isDate(element: FormElement) {
    if (element.type === FormElementType.DATE) {
      return true;
    }
    return false;
  }

  isTextarea(element: FormElement) {
    if (element.type === FormElementType.TEXTAREA) {
      return true;
    }
    return false;
  }

  isCheckbox(element: FormElement) {
    if (element.type === FormElementType.CHECKBOX) {
      return true;
    }
    return false;
  }

  isImage(element: FormElement) {
    if (element.type === FormElementType.IMAGE) {
      return true;
    }
    return false;
  }

  isSelect(element: FormElement) {
    if (element.type === FormElementType.SELECT) {
      return true;
    }
    return false;
  }

  isRow(element: FormElement | FormElement[]) {
    if (element instanceof Array) {
      return true;
    }
    return false;
  }

  isColumn(element: FormElement) {
    if (element.type === FormElementType.COLUMN) {
      return true;
    }
    return false;
  }

  getValue(data: string | Function, element: FormElement) {
    if (element.field instanceof Function) {
      return element.field(data);
    }
    let field = element.field;
    if (_.isEmpty(field)) {
      return data;
    }
    if (element.type === FormElementType.IMAGE) {
      field = `${field}.url`;
    }

    return _.get(data, field);
  }
}
