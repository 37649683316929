




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Watch, Prop } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import * as _ from 'lodash';
import { FormElement } from '../shared/types/form-element.class';
import { File } from '../shared/types/file.model';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    vueDropzone: vue2Dropzone,
  },
})
export default class BaseFormFileInput extends Vue {
  @Model('change', {
    type: Object,
  })
  data!: File | object;

  @Prop()
  element!: FormElement;

  $refs!: Vue['$refs'] & {
    dropzoneComponent: any;
  };

  currentFile: unknown = null;

  id = `dz-${Math.floor(Math.random() * 1000)}`;

  dropzoneOptions = {
    url: `${process.env.VUE_APP_API}/files`,
    useCustomSlot: true,
    thumbnailWidth: null,
    thumbnailHeight: null,
    maxFilesize: 5,
    maxFiles: 1,
    previewTemplate: `
  <div class="dz-preview dz-preview-single">
    <div class="dz-preview-cover ben">
      <img class="dz-preview-img" src="data:image/svg+xml,%3csvg3c/svg%3e" alt="..." data-dz-thumbnail >
    </div>
  </div>
  `,
  };

  get url() {
    if (!this.element || !this.data) {
      return '';
    }
    const file = this.getValue(this.data, this.element);
    return file?.url;
  }

  @Watch('data')
  onModelChanged(newVal: File | unknown) {
    if (this.isDisabled) {
      return;
    }
    this.$refs.dropzoneComponent.removeAllFiles();
    if (!newVal) {
      return;
    }

    let newFile: File;

    if (newVal instanceof File) {
      newFile = newVal;
    } else {
      newFile = this.getValue(newVal, this.element);
    }
    if (!newFile) {
      return;
    }

    this.$refs.dropzoneComponent.manuallyAddFile(
      {
        size: newFile.size,
        name: newFile.originalName,
        type: 'image/jpeg',
      },
      newFile.url,
    );
    document.getElementById(this.id).classList.add('dz-max-files-reached');
  }

  get isDisabled() {
    return this.element.options.disabled;
  }

  getValue(data: unknown, element: FormElement) {
    if (element.field instanceof Function) {
      return element.field(data);
    }

    return _.get(data, element.field);
  }

  onFileCompleted(file: any, response: any) {
    document.getElementById(this.id).classList.add('dz-max-files-reached');
    if (this.element && typeof this.element.field === 'string') {
      return _.set(this.data, this.element.field, response);
    }
    return (this.data = response);
  }

  onFileAdded(file: File) {
    if (this.currentFile) {
      this.$refs.dropzoneComponent.removeFile(this.currentFile);
    }
    this.currentFile = file;
  }

  mounted() {
    this.onModelChanged(this.data);
  }
}
