import { BaseModel } from './base.model';

export class File extends BaseModel {
  constructor(
    public originalName?: string,
    public currentName?: string,
    public size?: string,
    public mimeType?: string,
    public url?: string,
  ) {
    super();
  }
}
