










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit, Model } from 'vue-property-decorator';
import * as _ from 'lodash';

import BaseFormElement from '../components/BaseFormElement.vue';
import {
  FormElement,
  FormElementType,
} from '@/shared/types/form-element.class';

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseFormElement } })
export default class BaseForm extends Vue {
  @Model('change', {
    type: Object,
  })
  data!: object;

  @Prop()
  elements!: FormElement[];

  onChange(element: FormElement, value: any) {
    _.set(this.data, element.field as string, value);
  }

  isDisabled(element: FormElement) {
    if (element.options.disabled instanceof Function) {
      return element.options.disabled(element);
    }
    return element.options.disabled;
  }

  isImage(element: FormElement) {
    if (element.type === FormElementType.IMAGE) {
      return true;
    }
    return false;
  }

  isSelect(element: FormElement) {
    if (element.type === FormElementType.SELECT) {
      return true;
    }
    return false;
  }

  isRow(element: FormElement) {
    if (element.type === FormElementType.ROW) {
      return true;
    }
    return false;
  }

  isColumn(element: FormElement) {
    if (element.type === FormElementType.COLUMN) {
      return true;
    }
    return false;
  }

  getValue(data: any, element: FormElement) {
    if (element.field instanceof Function) {
      return element.field(data);
    }

    return _.get(data, element.field);
  }

  onSubmit() {
    return false;
  }
}
