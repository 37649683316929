import { ValidationRule } from 'vee-validate/dist/types/types';

export enum FormElementType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  IMAGE = 'image',
  SELECT = 'select',
  ROW = 'row',
  COLUMN = 'col',
  TEXTAREA = 'textarea',
  DATE = 'date',
  CHECKBOX = 'cb',
}

export class FormElementOptions {
  constructor(
    public optionsData?: unknown[],
    public optionLabel?: string,
    public optionValue?: string,
    public printOption?: Function,
    public disabled: boolean | Function = false,
    public tooltip?: string,
    public colSize?: number,
  ) {}
}

export class FormElement {
  constructor(
    public field: string | Function,
    public label?: string,
    public options: FormElementOptions = new FormElementOptions(),
    public rules?: string,
    public type: FormElementType = FormElementType.TEXT,
  ) {
    if (!label && typeof field === 'string') {
      this.label = field;
    }
  }
}
